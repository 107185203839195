module.exports = {
  siteTitle: 'Hi! I\'m Stalinbalraj!',
  siteDescription: `Stalinbalraj's curriculum website`,
  keyWords: ['gatsbyjs', 'react', 'curriculum'],
  authorName: 'Stalinbalraj Sowriappan',
  twitterUsername: 'StalinSbr',
  githubUsername: 'Stalinbalraj',
  authorAvatar: '/images/avatar1.jpeg',
  authorDescription: `I am a Front End developer with industry experience building Websites, Web applications and Hybrid mobile application.
  I specialize in <code> JavaScript, HTML5, CSS3 </code> and have professional experience working with <code>Angular, ReactJS.</code>
  I have a experience with IoT domain and Banking & Finance domains.
  Well acquainted with the Waterfall and Agile Methodologies of software development.
  Involved with UX discussions with Product managers and UX specialists to get the best product delivered with top notch quality.`,
  skills: [
    {
      name: 'HTML',
      level: 75
    },
    {
      name: 'CSS',
      level: 75
    },
    {
      name: 'Javascript',
      level: 80
    },
    {
      name: 'Angular',
      level: 70
    },
    {
      name: 'React',
      level: 50
    },
    {
      name: 'NodeJs',
      level: 40
    }
  ],
  jobs: [
    {
      company: "CISCO",
      begin: {
        month: 'APR',
        year: '2020'
      },
      duration: '',
      occupation: "Software Engineer-II",
      description: "Working on Cisco Webex platform"
  
    },
    {
      company: "Siemens Technology India",
      begin: {
        month: 'Mar',
        year: '2019'
      },
      duration: '1 year 1 month',
      occupation: "Software Developer",
      description: "Worked on building an IoT product with Angular. Involved in building Web application using Angular, Angular Material. Maintaining code versioning using GitLab. Worked on several POCs on IoT application using Angular, MQTT, MindSphere. Implementing charts using Highcharts.js. Worked on POC which is a web application for showing Heat Temperature in the building using Angular, Highcharts, MQTT, AWS. Followed Agile methodologies of software development. Creating CI CD pipelines using Cloud Foundry"
  
    }, {
      company: "TCS",
      begin: {
        month: 'Sep',
        year: '2015'
      },
      duration: '3 years 5 months',
      occupation: "FrontEnd developer",
      description: "I was responsible for developing and maintaining Hybrid Mobile Applications for Banking and Finance domain. Involved in design and development of the hybrid mobile application using cutting edge technologies like Angular (2/4), HTML5, CSS3, JAVASCRIPT, Bootstrap, Cordova. This application can be used in android and iOS devices. Exhibited excellent leadership qualities by leading a team of two fresher’s. Followed Agile methodologies of software development."
    }
    /* ... */
  ],
  social: {
    twitter: "https://twitter.com/StalinSbr",
    linkedin: "https://www.linkedin.com/in/stalinbalraj-sowriappan-25b7a48b",
    github: "https://github.com/Stalinbalraj",
    email: "stalinbalraj@gmail.com"
  },
  siteUrl: 'https://stalinbalrajsowriappan.netlify.com/',
  pathPrefix: '/gatsby-starter-cv', // Note: it must *not* have a trailing slash.
  siteCover: '/images/cover.jpeg',
  googleAnalyticsId: 'UA-000000000-1',
  background_color: '#ffffff',
  theme_color: '#25303B',
  display: 'minimal-ui',
  icon: 'src/assets/icon.png',
  headerLinks: [
    {
      label: 'Stalinbalraj Sowriappan',
      url: '/',
    }
  ]
}